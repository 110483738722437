/* eslint-disable react/no-danger */
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'

import useStyles from './store-location-card-style'
import { Carousel, Title, Col } from '../../../ui'
import combineClassNames from '../../../helpers/combineClassNames'
import getStoreInfoFromDescription from '../../../helpers/get-store-info-from-description'
import getAddressLinesFromStore from '../../../helpers/get-address-lines-from-store'

const StoreLocationCardView = (props) => {
  const styles = useStyles()
  const { location, siteUrl } = props
  const { i18n, t } = useTranslation()
  const currentLocale = i18n.language
  const googleMapUrl = location.lat && location.long
    ? `https://maps.google.com/maps?q=${location.lat}%2C${location.long}&t=&z=18&ie=UTF8&iwloc=&output=embed`
    : ''

  const formattedPhone = _.get(location, 'phoneData.formatted')
  const locationPhone = _.get(location, 'phone')
  const fallbackPhone = locationPhone
    ? [{
      content: `${t('screens.stores.hotLine')}: ${formattedPhone}`,
      url: `tel:+${locationPhone.replace(/[^0-9]+/g, '')}`,
    }]
    : []
  const descriptionHtml = (_.get(location, 'description') || '').trim()
  const descriptionPhone = getStoreInfoFromDescription('phone', descriptionHtml)
  const openingHours = _.map(getStoreInfoFromDescription('time', descriptionHtml), 'content')
  const info = _.map(getStoreInfoFromDescription('article', descriptionHtml), 'content')
  const phone = _.isEmpty(descriptionPhone)
    ? fallbackPhone : getStoreInfoFromDescription('phone', descriptionHtml)
  const email = getStoreInfoFromDescription('email', descriptionHtml)
  const address = getAddressLinesFromStore(location)
  const contacts = _.concat(phone, email)

  return (
    <div className={combineClassNames([styles.row, styles.container])}>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'Store',
            image: _.get(location, 'bannerImages.0.versions.webLarge'),
            name: location.name,
            address: {
              '@type': 'PostalAddress',
              streetAddress: _.compact([
                _.get(location, 'address.streetLine1', undefined),
                _.get(location, 'address.streetLine2', undefined),
              ]),
              addressLocality: _.get(location, 'address.district', undefined),
              addressRegion: _.get(location, 'address.state', undefined),
              postalCode: _.get(location, 'address.zip', undefined),
              addressCountry: _.get(location, 'address.country', undefined),
            },
            geo: {
              '@type': 'GeoCoordinates',
              latitude: location.lat,
              longitude: location.long,
            },
            url: `${siteUrl}/${currentLocale}/store-locations/`,
            telephone: _.get(location, 'phone', undefined),
            // TODO: need to add open/close date time
            // openingHoursSpecification: [
            //   {
            //     '@type': 'OpeningHoursSpecification',
            //     dayOfWeek: [
            //       'Monday',
            //       'Tuesday',
            //       'Wednesday',
            //       'Thursday',
            //       'Friday',
            //       'Saturday',
            //     ],
            //     opens: '08:00',
            //     closes: '23:59',
            //   },
            //   {
            //     '@type': 'OpeningHoursSpecification',
            //     dayOfWeek: 'Sunday',
            //     opens: '08:00',
            //     closes: '23:00',
            //   },
            // ],
          })}
        </script>
      </Helmet>
      {
        !_.isEmpty(location.bannerImages) && (
          <Col className={styles.hero}>
            {
              _.size(location.bannerImages) > 1
                ? (
                  <Carousel
                    images={location.bannerImages}
                    autoplay
                    className={styles.carouselLocation}
                    imageBoxClassName={styles.imageBannerBox}
                  />
                ) : (
                  <div className={styles.imageBannerBox}>
                    <img src={_.get(location, 'bannerImages.0.versions.webLarge')} alt="" />
                  </div>
                )
            }
          </Col>
        )
      }
      <Col className={styles.content}>
        <section className={styles.section}>
          <Title text={location.name} />
          <h6 className={styles.title}>{t('screens.stores.location')}</h6>
          <div className={styles.infoBox}>
            {
              address.map((line) => (
                <p key={line} dangerouslySetInnerHTML={{ __html: line }} />
              ))
            }
          </div>
          {!_.isEmpty(contacts) && (
            <div className={styles.infoBox}>
              {_.map(contacts, ({ url, content }) => (
                <p key={content}>
                  <a href={url} className={styles.link}>
                    {content}
                  </a>
                </p>
              ))}
            </div>
          )}
        </section>
        {
          !_.isEmpty(openingHours) && (
            <section className={styles.section}>
              <h6 className={styles.title}>{t('screens.stores.hours')}</h6>
              <div className={styles.infoBox}>
                {
                  openingHours.map((line) => (
                    <p key={line} dangerouslySetInnerHTML={{ __html: line }} />
                  ))
                }
              </div>
            </section>
          )
        }
        {
          !_.isEmpty(info) && (
            <section className={styles.section}>
              <div className={styles.infoBox}>
                {
                  info.map((line) => (
                    <div key={line} dangerouslySetInnerHTML={{ __html: line }} />
                  ))
                }
              </div>
            </section>
          )
        }
      </Col>
      {
        googleMapUrl && (
          <Col className={styles.map}>
            <div className={styles.mapStyle}>
              <iframe
                src={googleMapUrl}
                width="100%"
                height="100%"
                frameBorder="0"
                allowFullScreen=""
                aria-hidden="false"
                title="store-map"
              />
            </div>
          </Col>
        )
      }
    </div>
  )
}

StoreLocationCardView.propTypes = {
  location: PropTypes.object.isRequired,
}

export default StoreLocationCardView
