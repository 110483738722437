import _ from 'lodash'
import { createUseStyles } from 'react-jss'
import parseAspectRatio from '../../../helpers/parse-aspect-ratio'
import getStyleFromTheme from '../../../helpers/get-style-from-theme'

const useStyles = createUseStyles((theme) => {
  const imageBannerAspectRatioStyle = getStyleFromTheme({
    theme,
    key: 'config.ratioStoreLocationsImageBanner',
    defaultValue: 'auto',
    styleProperty: 'aspectRatio',
    getValueFn: parseAspectRatio,
  })

  // If heroImageRatio is larger then 3:2, hero image will placed above store details and map
  const heroImageRatio = _.get(theme, 'config.ratioStoreLocationsImageBanner.tablet')
    || _.get(theme, 'config.ratioStoreLocationsImageBanner', '1:1')
  const heroImageRatioInNumber = _.divide(
    ..._.map(
      _.map(_.split(heroImageRatio, /[:|/|,]/), _.trim),
      _.toNumber,
    ),
  )
  const isHeroImageTooWide = heroImageRatioInNumber > 1.5

  return {
    container: {
      width: '100%',
      backgroundColor: theme.white,
      padding: [[`${theme.doubleGap}rem`, 0]],
      [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
        flexWrap: isHeroImageTooWide ? 'wrap' : 'nowrap',
      },
    },
    hero: {
      [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
        order: isHeroImageTooWide ? 1 : 3,
        flexBasis: isHeroImageTooWide ? '100%' : '36%',
        flexGrow: 1,
        flexShrink: 1,
        paddingRight: '3rem',
        marginBottom: isHeroImageTooWide ? '3rem' : 0,
      },
    },
    content: {
      paddingTop: '1.5rem',
      '& p': {
        lineHeight: 1.43,
        fontSize: '1.4rem',
        color: theme.colors.text,
        margin: 0,
      },
      [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
        order: 2,
        flexBasis: isHeroImageTooWide ? '50%' : '28%',
        flexGrow: 0,
        flexShrink: 0,
        paddingRight: '3rem',
        paddingTop: 0,
      },
    },
    map: {
      [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
        order: 4,
        flexBasis: '36%',
        flexGrow: 1,
        flexShrink: 1,
        paddingRight: '3rem',
      },
    },
    imageBannerBox: {
      ...imageBannerAspectRatioStyle,
      overflow: 'hidden',
      '& img': {
        objectFit: 'cover',
        objectPosition: 'center top',
        width: '100%',
        height: '100%',
      },
    },
    link: {
      color: 'currentColor',
      textDecoration: 'none',
    },
    row: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'space-between',
      [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
        flexDirection: 'row',
        width: 'calc(100% + 3rem)',
      },
    },
    section: {
      marginBottom: `${theme.gap}rem`,
    },
    title: {
      textTransform: 'uppercase',
      marginTop: '3rem',
    },
    infoBox: {
      marginTop: '2rem',
      marginBottom: '2rem',
    },
    mapStyle: {
      width: '100%',
      height: 'auto',
      // minHeight: '35rem',
      display: 'grid',
      ...imageBannerAspectRatioStyle,
    },
    carouselLocation: {
      img: {
        width: 'inherit',
      },
    },
  }
})

export default useStyles
