import React from 'react'
import { useTranslation } from 'react-i18next'
import useStyles from './store-locations-style'
import StoreLocationCardView from './store-locations-card/store-location-card-view'
import {
  Layout, Loading, Title, SEO,
} from '../../ui'

function StoreLocationsView({
  pageReady,
  stores,
  seoTitle,
  siteUrl,
}) {
  const { t } = useTranslation()
  const { container } = useStyles()
  return (
    <Layout>
      {/* passing page specific data for SEO */}
      <SEO title={seoTitle} />

      {/* Please replace the actual content */}
      <>
        {pageReady ? (
          <div className={container}>
            <Title text={t('screens.stores.title')} />
            <div>
              {
                stores.map(
                  (store) => (
                    <StoreLocationCardView
                      key={`store-location-${store.id}`}
                      location={store}
                      siteUrl={siteUrl}
                    />
                  ),
                )
              }
            </div>
          </div>
        ) : (
          <Loading />
        )}
      </>
    </Layout>

  )
}

export default StoreLocationsView
