import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  // cancelRequest,
  useSystemSettings,
  useStores,
} from 'react-omnitech-api'
import StoreLocationsView from './store-locations-view'

function StoreLocationsController() {
  // prepare hook
  const { t } = useTranslation()
  const { stores, storesReady } = useStores()
  const { getSystemSetting } = useSystemSettings()

  // // internal state
  // const [stores, setStores] = useState([])
  // const [pageReady, setPageReady] = useState(false)

  // // local variable
  const seoTitle = t('screens.storeLocations.seo.title')

  // /**
  //  * fetchStoresApi
  //  * get stores data from API
  //  */
  // const fetchStoresApi = useCallback(async () => {
  //   try {
  //     // api call option
  //     const option = {
  //       includes: [
  //         'address',
  //         'banner_images',
  //         'description',
  //       ].join(','),
  //     }
  //     // call api
  //     const { stores: data } = await fetchStores(option)
  //     setStores(data)
  //   } catch (error) {
  //     // TODO: when fail to load content group, what should be shown?
  //   } finally {
  //     setPageReady(true)
  //   }
  // }, [fetchStores])

  // /**
  //  * load article content group when page loaded
  //  */
  // useEffect(() => {
  //   fetchStoresApi()

  //   return function fetchStoresApiCleanUp() {
  //     cancelRequest.cancelAll([
  //       'fetchStores',
  //     ])
  //   }
  // }, [fetchStoresApi])

  const viewProps = {
    stores,
    pageReady: storesReady,
    seoTitle,
    // for structured data
    siteUrl: getSystemSetting('frontend.site_url'),
  }

  return (
    <StoreLocationsView {...viewProps} />
  )
}

export default StoreLocationsController
